import React from 'react';
import axios from 'axios'
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import { withRouter } from "react-router-dom"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class NameForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            show2: false
        };
    }
    render() {
        const handleClose = () => this.setState({ show: false })
        const handleOpen = () => this.setState({ show: true })
        const handleSucess = () => this.props.history.push("/thank-you");
        return (
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    acceptTerms: true
                }}
                validationSchema={Yup.object().shape({
                    firstName: Yup.string()
                        .required('First Name is required'),
                    lastName: Yup.string()
                        .required('Last Name is required'),
                    email: Yup.string()
                        .email('Email is invalid')
                        .required('Email is required'),
                    acceptTerms: Yup.bool()
                        .oneOf([true], 'Accept Ts & Cs is required')
                })}
                onSubmit={values => {
                    console.log("test");
                        axios.post('https://django.platinumseltzer.com/api/entry/', {
                            firstname: values.firstName,
                            lastname: values.lastName,
                            email: values.email,
                            state: 'NA',
                            sweeps: 2,
                        }).then(function (response) {
                            cookies.set('myEmail', values.email, {path: '/'});
                            handleSucess()
                        }).catch(function (response) {
                            handleOpen()
                        })
                }}>
                {({errors, status, touched}) => (
                    <Form id="name">
                        <Modal show={this.state.show} onHide={handleClose} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>An error has occured please try again later.</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>We Apologize, The system was unable to save your entry please try again shortly.</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Continue
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="container-fluid">
                        <div className="flex-row">
                            <div className="flex-column justify-content-center">
                            <Field name="firstName" type="text" placeholder="First Name"
                                   className={'text' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}/>
                            <ErrorMessage name="firstName" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="flex-column">
                            <Field name="lastName" type="text" placeholder="Last Name"
                                   className={'text' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}/>
                            <ErrorMessage name="lastName" component="div" className="invalid-feedback"/>
                            </div>

                            <div className="flex-column">
                            <Field name="email" type="text" placeholder="Email"
                                   className={'text' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="flex-column">
                        <div className="form-check">
                            <Field type="checkbox" name="acceptTerms"
                                   className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')}/>
                            <label htmlFor="acceptTerms" className="form-check-label">I have read and agree to the Official Rules*</label>
                            <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback"/>
                        </div>
                            </div>
                        </div>
                        </div>
                        <div className="form-group">
                            <button type="submit"  className="btn btn-gugo2 mr-2">Register</button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}
export default withRouter(NameForm);
