import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from "react-bootstrap/Image";
import product from './img/product.svg';
import {animated, useSpring} from 'react-spring'
import DateForm from "./DateForm";

const calc = (x, y) => [-(y - window.innerHeight / 2000) / 200, (x - window.innerWidth / 2000) / 200, .9]
const trans = (x, y, s) => `perspective(600px) rotateX(${-x}deg) rotateY(${-y}deg) scale(${s})`


function ProductHolder() {
    const [props, set] = useSpring(() => ({xys: [0, 0, 1], config: {mass: 1, tension: 500, friction: 100}}))
    return (
        <animated.div
            class="Product-Holder"
            onMouseMove={({clientX: x, clientY: y}) => set({xys: calc(x, y)})}
            onMouseLeave={() => set({xys: [0, 0, 1]})}
            style={{transform: props.xys.interpolate(trans)}}>
            <Image src={product} alt="Pacifico" className="Product-Image"/>
        </animated.div>
    )
}

class AgeGate extends React.Component {
    render() {
        return (
            <div className="App">
                <div className="main-logo">
                    <div className="main-overlay">
                        <Container fluid className="main-multiply">
                            <Row className="Main-Row">
                                <Col xs={9} lg={9} className="Main-Col">
                                    <Container fluid className="Main-Container d-flex flex-column">
                                        <Row className="flex-grow-1">
                                            <Col className="sub-main-col">
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="sub-main-col">
                                                <p className="App-Top-Line-1">Enter for a chance to WIN Incredible Ski, Snowboarding & Staycation Gear!</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="sub-main-col">
                                                <p className="App-Top-Line-2">YOU MUST BE OF LEGAL DRINKING AGE PLEASE FILL OUT YOUR DATE OF BIRTH</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="sub-main-col">
                                                <p className="App-Top-Line-3">TO GET STARTED!</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="sub-main-col">
                                                <DateForm/>
                                            </Col>
                                        </Row>
                                        <Row className='flex-grow-1'>
                                            <Col className="sub-main-col">
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col xs={3} lg={3} className="Main-Col">
                                    <Container className="Main-Container">
                                        <Row className="Main-Row">
                                            <Col className="right-sub-main-col">
                                                <ProductHolder/>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Container fluid className="disclaimer-container">
                    <Row>
                        <Col className="sub-main-col">
                            <p className="App-Disclaimer">
                                NO PURCHASE NECESSARY. Open only to legal U.S. residents residing in CT, DE, MA, ME, NH,
                                NJ, NY, PR, RI or VT (“Eligibility Area”) who are 21 or older at time of entry. Void
                                outside Eligibility Area and where prohibited. Promotion subject to Official Rules and
                                additional restrictions on eligibility. One (1) winner will receive Grand prize of
                                snowboard, beanie, gloves, speaker, water bottle and gift card with ARV $742.00; Sixteen
                                (16) winners will each receive First prize of beanie, gloves and backpack with ARV
                                $214.00. Odds of winning depend on number of eligible entries received. No alcohol is
                                awarded in any prize. Starts at 12:00 AM (ET) on 1/15/2021 and ends at 11:59 PM (ET) on
                                2/28/2021. For complete details, including how to enter, see Official Rules at
                                www.gearuppacifico.com/.
                                Sponsor: Crown Imports LLC, 131 South Dearborn Street, Chicago, IL 60603.
                            </p>
                            <br/>
                            <p className="App-footer">
                                <a className="linkbtn text-decoration-none"
                                   href="https://www.gearuppacifico.com/pdf/rules.pdf">official rules</a> | <a
                                className="linkbtn text-decoration-none"
                                href="https://www.discoverpacifico.com/privacy-policy">PRIVACY POLICY</a> | <a
                                className="linkbtn text-decoration-none"
                                href="https://www.discoverpacifico.com/terms-conditions">TERMS OF USE</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default AgeGate;
