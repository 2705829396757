import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import App from './AgeGate';
import App2 from "./Registration";
import App3 from "./Sucess";
// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {
    return (
        <Router>
            {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route path="/registration">
                    <Register/>
                </Route>
                <Route path="/thank-you">
                    <Thanks/>
                </Route>
            </Switch>
        </Router>
    );
}

// You can think of these components as "pages"
// in your app.

function Home() {
    return (
        <App/>
    );
}

function Register() {
    return (
        <App2/>
    );
}

function Thanks() {
    return (
        <App3/>
    );
}
