import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Cookies from 'universal-cookie';
import {withRouter} from "react-router-dom";

const cookies = new Cookies();

class DateForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    render() {
        const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)
        const handleClose = () => window.location = 'http://google.com'
        return (
            <Formik
                initialValues={{
                    birthMonth: '',
                    birthDay: '',
                    birthYear: '',
                }}
                validationSchema={Yup.object().shape({
                    birthMonth: Yup.string()
                        .required('Month is required'),
                    birthDay: Yup.string()
                        .required('Day is required'),
                    birthYear: Yup.string()
                        .required('Year is required'),
                })}
                onSubmit={values => {
                    if (getAge(values.birthYear + '-' + values.birthMonth + '-' + values.birthDay) >= 21) {
                        cookies.set('myAccess', 'True', {path: '/'});
                        this.props.history.push("/registration");
                    } else {
                        //alert(values.birthYear+'-'+values.birthMonth+'-'+values.birthDay);
                        this.setState({show: true})
                    }
                }}>
                {({errors, status, touched}) => (
                    <Form id="age">
                        <Modal show={this.state.show} onHide={handleClose} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>Notice!!!!!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>We Apologize, However You Must be 21 year of age or older to Enter.</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Continue
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="d-flex container-fluid justify-content-center p-1 flex-wrap">
                            <div className="d-flex flex-row flex-wrap">
                                <div className="flex-column">
                                    <Field name="birthMonth" as="select"
                                           className={'select form-control' + (errors.birthMonth && touched.birthMonth ? ' is-invalid' : '')}>
                                        <option>MM</option>
                                        <option value="01">January</option>
                                        <option value="02">Febuary</option>
                                        <option value="03">March</option>
                                        <option value="04">April</option>
                                        <option value="05">May</option>
                                        <option value="06">June</option>
                                        <option value="07">July</option>
                                        <option value="08">August</option>
                                        <option value="09">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </Field>
                                </div>
                                <div className="flex-column">
                                    <Field name="birthDay" as="select"
                                           className={'select form-control' + (errors.birthDay && touched.birthDay ? ' is-invalid' : '')}>
                                        <option>DD</option>
                                        <option value="01">1</option>
                                        <option value="02">2</option>
                                        <option value="03">3</option>
                                        <option value="04">4</option>
                                        <option value="05">5</option>
                                        <option value="06">6</option>
                                        <option value="07">7</option>
                                        <option value="08">8</option>
                                        <option value="09">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                    </Field>
                                </div>
                                <div className="flex-column">
                                    <Field name="birthYear" as="select"
                                           className={'select form-control' + (errors.birthYear && touched.birthYear ? ' is-invalid' : '')}>
                                        <option>YYYY</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                        <option value="2002">2002</option>
                                        <option value="2001">2001</option>
                                        <option value="2000">2000</option>
                                        <option value="1999">1999</option>
                                        <option value="1998">1998</option>
                                        <option value="1997">1997</option>
                                        <option value="1996">1996</option>
                                        <option value="1995">1995</option>
                                        <option value="1994">1994</option>
                                        <option value="1993">1993</option>
                                        <option value="1992">1992</option>
                                        <option value="1991">1991</option>
                                        <option value="1990">1990</option>
                                        <option value="1989">1989</option>
                                        <option value="1988">1988</option>
                                        <option value="1987">1987</option>
                                        <option value="1986">1986</option>
                                        <option value="1985">1985</option>
                                        <option value="1984">1984</option>
                                        <option value="1983">1983</option>
                                        <option value="1982">1982</option>
                                        <option value="1981">1981</option>
                                        <option value="1980">1980</option>
                                        <option value="1979">1979</option>
                                        <option value="1978">1978</option>
                                        <option value="1977">1977</option>
                                        <option value="1976">1976</option>
                                        <option value="1975">1975</option>
                                        <option value="1974">1974</option>
                                        <option value="1973">1973</option>
                                        <option value="1972">1972</option>
                                        <option value="1971">1971</option>
                                        <option value="1970">1970</option>
                                        <option value="1969">1969</option>
                                        <option value="1968">1968</option>
                                        <option value="1967">1967</option>
                                        <option value="1966">1966</option>
                                        <option value="1965">1965</option>
                                        <option value="1964">1964</option>
                                        <option value="1963">1963</option>
                                        <option value="1962">1962</option>
                                        <option value="1961">1961</option>
                                        <option value="1960">1960</option>
                                        <option value="1959">1959</option>
                                        <option value="1958">1958</option>
                                        <option value="1957">1957</option>
                                        <option value="1956">1956</option>
                                        <option value="1955">1955</option>
                                        <option value="1954">1954</option>
                                        <option value="1953">1953</option>
                                        <option value="1952">1952</option>
                                        <option value="1951">1951</option>
                                        <option value="1950">1950</option>
                                        <option value="1949">1949</option>
                                        <option value="1948">1948</option>
                                        <option value="1947">1947</option>
                                        <option value="1946">1946</option>
                                        <option value="1945">1945</option>
                                        <option value="1944">1944</option>
                                        <option value="1943">1943</option>
                                        <option value="1942">1942</option>
                                        <option value="1941">1941</option>
                                        <option value="1940">1940</option>
                                        <option value="1939">1939</option>
                                        <option value="1938">1938</option>
                                        <option value="1937">1937</option>
                                        <option value="1936">1936</option>
                                        <option value="1935">1935</option>
                                        <option value="1934">1934</option>
                                        <option value="1933">1933</option>
                                        <option value="1932">1932</option>
                                        <option value="1931">1931</option>
                                        <option value="1930">1930</option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex container-fluid justify-content-center p-1">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column">
                                    <ErrorMessage name="birthDay" component="div" className="invalid-feedback-block" style={{color: '#ed1f57'}}/>
                                    <ErrorMessage name="birthMonth" component="div" className="invalid-feedback-block" style={{color: '#ed1f57'}}/>
                                    <ErrorMessage name="birthYear" component="div" className="invalid-feedback-block" style={{color: '#ed1f57'}}/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex container-fluid justify-content-center p-1">

                    <div className="d-flex flex-row">
                        <div className="d-block d-sm-none">
                            <button type="submit" className="btn btn-gugo mr-2">Enter</button>
                        </div>
                    <div className="flex-column d-none d-sm-block">
                    <button type="submit" className="btn btn-gugo mr-2">Enter</button>
                    </div>
                    </div>
                        </div>
                    </Form>
                    )}
                    </Formik>
                    )
                    }
                }

    export default withRouter(DateForm);
